import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import styled from 'styled-components';

// Layout component
import Layout from '../components/layouts/Layout';
import HorizontalMenu from '../components/HorizontalMenu';
import './privacy-policy.css';

const Content = styled.div`
    padding: 10px;

    > h3 {
        font-size: 22px;
        font-weight: bold;
    }

    > strong {
        font-weight: bold !important;
    }

    p > a {
        color: white;
        font-weight: bold;
    }
`;

const IndexPage = () => {
    const { t } = useTranslation('TermOfUsePro');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_TERM_OF_USE_PRO')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_TERM_OF_USE_PRO')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1
                        style={{ fontSize: '5vh' }}
                        className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{__html: t('TITLE')}}></h1>
                    <HorizontalMenu
                        list={[
                            {
                                url: '/privacy-policy/',
                                category: t('PRIVACY_POLICY')
                            },
                            {
                                url: '/terms-of-use/',
                                category: t('TERM_OF_USE')
                            },
                            {
                                url: '/terms-of-use-pro/',
                                isActive: true,
                                category: t('TERM_OF_USE_PRO')
                            },
                            {
                                url: '/privacy-policy-rueil/',
                                category: t('PRIVACY_POLICY_RUEIL')
                            },
                            {
                                url: '/privacy-policy-mission-herault/',
                                category: t('PRIVACY_POLICY_MISSION_HERAULT')
                            },
                            {
                                url: '/privacy-policy-paris-region-aventures/',
                                category: t('PRIVACY_POLICY_PARIS_REGION_AVENTURES')
                            },
                            {
                                url: '/privacy-policy-colmar-aventure/',
                                category: t('PRIVACY_POLICY_COLMAR_AVENTURE')
                            },
                            {
                                url: '/privacy-policy-mont-saint-michel-aventures/',
                                category: t('PRIVACY_POLICY_MONT_SAINT_MICHEL_AVENTURE')
                            }
                        ]}
                    />

                    <Content dangerouslySetInnerHTML={{ __html: t('CONTENT') }} />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;
